import React, { useEffect, useState } from 'react';

import api from '../../services/api';

import { Col, Dropdown, Empty, Input, Menu, Modal, notification, Row, Select, Tooltip } from 'antd';

import Spinner from '../../components/Spinner';
import PageContainer from '../../containers/PageContainer';

import { Influencer as InfluencerModel } from '../../models/entities/influencer';
import { RegionEnum } from '../../models/enums/RegionEnum';
import allBrazilianStates from "../../models/enums/allBrazilianStates.json";

import {
    Centralizer,
    Container,
    ContentClick,
    ContentTop,
    FormatName,
    Table,
    TableContainer,
    Tag,
    ThreeDotsIcon,
    TotalText
} from './styles';
const Influencer = () => {
    const [loading, setLoading] = useState(false);
    const [influencersInfo, setInfluencersInfo] = useState<InfluencerModel[]>([]);
    const [filteredData, setFilteredData] = useState<InfluencerModel[]>([]);

    const [filters, setFilters] = useState({ city: '', state: '' });

    const [shouldReload, setShouldReload] = useState(false);

    const [editingComment, setEditingComment] = useState<{ [key: string]: string }>({});
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    useEffect(() => {
        const getInfluencersInfo = async () => {
            setLoading(true);
            try {
                const { data } = await api.get(`/influencers`, {
                    params: { page: pagination.current, size: pagination.pageSize },
                });

                setInfluencersInfo(data.content);
                setPagination((prev) => ({
                    ...prev,
                    total: data.totalElements,
                }));
            } catch (error) {
                //@ts-ignore
                const _description = error?.response?.data?.message;

                notification.error({
                    message: `Erro ao vincular dados da loja`,
                    description: _description,
                    duration: 3,
                });
            } finally {
                setLoading(false);
                setShouldReload(false);
            }
        };
        getInfluencersInfo();
    }, [shouldReload, pagination.current, pagination.pageSize]);

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const phoneRegex = /^\(\d{2}\)/;

    const formatCPF = (cpf) => {
        return cpf
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    const formatSocialLink = (username, baseUrl, isTikTok = false) => {
        if (!username) return "Não informado";
        const cleanUsername = username.replace(/^@/, "");
        const profileUrl = isTikTok ? `${baseUrl}@${cleanUsername}` : `${baseUrl}${cleanUsername}`;

        return (
            <ContentClick href={profileUrl} target="_blank" rel="noopener noreferrer">
               @{cleanUsername}
            </ContentClick>

        );
    };

    useEffect(() => {
        const { city, state } = filters;
        const filtered = influencersInfo.filter((influencer) => {
            const matchCity = city ? influencer.city === city : true;
            const matchState = state ? influencer.state === state : true;
            return matchCity && matchState;
        });
        setFilteredData(filtered);
    }, [filters, influencersInfo]);

    const handleCommentChange = (id: string, value: string) => {
        setEditingComment((prev) => ({ ...prev, [id]: value }));
    };

    const handleCommentSubmit = async (id: number) => {
        if (!editingComment[id]) return;

        try {
            await api.put(`/influencers/${id}`, { comment: editingComment[id] });

            setInfluencersInfo((prev) => {
                const updatedList = prev.map((influencer) =>
                    influencer.id === id ? { ...influencer, comment: editingComment[id] } : influencer
                );
                return [...updatedList];
            });

            setEditingComment((prev) => {
                const newState = { ...prev };
                delete newState[id];
                return newState;
            });

            notification.success({
                message: "Comentário atualizado com sucesso!",
                duration: 2,
            });

            setShouldReload(true);

        } catch (error) {
            //@ts-ignore
            const _description = error?.response?.data?.message;
            notification.error({
                message: `Erro ao postar comentário`,
                description: _description,
                duration: 3,
            });
        }
    };

    const removeInfluencer = async (id: number) => {
        Modal.confirm({
            title: "Confirmar exclusão",
            content: "Tem certeza que deseja deletar este influenciador?",
            centered: true,
            okText: "Sim",
            cancelText: "Cancelar",
            onOk: async () => {
                try {
                    await api.delete(`/influencers/${id}`);

                    setLoading(true);
                    setShouldReload(true);
                    notification.success({
                        message: `Usuário deletado com sucesso!`,
                        duration: 3,
                    });
                } catch (error) {
                    //@ts-ignore
                    const _description = error?.response?.data?.message;

                    notification.error({
                        message: `Erro ao deletar o influencer`,
                        description: _description,
                        duration: 3,
                    });
                } finally {
                    setLoading(false);
                    setShouldReload(false);
                }
            },
        });
    };

    const menu = (record) => {
        return (
            <>
                <Menu>
                    <Menu.Item key="1" onClick={() => removeInfluencer(record)}>
                        Remover
                    </Menu.Item>

                </Menu>
            </>
        );
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id - b.id,
            width: 100,
        },
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
            render: (text) => (
                <FormatName>
                    <Tooltip title={text}>
                        {text}
                    </Tooltip>
                </FormatName>
            ),
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: 100,
        },
        {
            title: "CPF",
            dataIndex: "cpf",
            key: "cpf",
            render: (cpf) => (cpf ? formatCPF(cpf) : "-"),
            sorter: (a, b) => a.cpf.localeCompare(b.cpf),
            width: 100,
        },
        {
            title: "Cidade",
            dataIndex: "city",
            key: "city",
            render: (text) => <FormatName>{text}</FormatName>,
            sorter: (a, b) => a.city.localeCompare(b.city),
            width: 100,
        },
        {
            title: "Estado",
            dataIndex: "state",
            key: "state",
            sorter: (a, b) => a.state.localeCompare(b.state),
            width: 100,
        },
        {
            title: "Região",
            dataIndex: "region",
            key: "region",
            render: (text) => <FormatName>{RegionEnum[text]}</FormatName>,
            sorter: (a, b) => RegionEnum[a.region].localeCompare(RegionEnum[b.region]),
            width: 100,
        },
        {
            title: "Telefone",
            dataIndex: "cell_phone",
            key: "cell_phone",
            render: (text) => (phoneRegex.test(text) || text),
            sorter: (a, b) => a.cell_phone.localeCompare(b.cell_phone),
            width: 150,
        },
        {
            title: "E-mail",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
            width: 150,
        },
        {
            title: "Instagram",
            dataIndex: "instagram",
            key: "instagram",
            render: (text) => formatSocialLink(text, "https://www.instagram.com/"),
            sorter: (a, b) => a.instagram.localeCompare(b.instagram),
            width: 150,
        },
        {
            title: "Tiktok",
            dataIndex: "tiktok",
            key: "tiktok",
            render: (text) => formatSocialLink(text, "https://www.tiktok.com/@"),
            sorter: (a, b) => a.tiktok.localeCompare(b.tiktok),
            width: 150,
        },
        {
            title: "Nº de Seguidores",
            dataIndex: "followers",
            key: "followers",
            sorter: (a, b) => a.followers - b.followers,
            width: 150,
        },
        {
            title: "Comentário",
            dataIndex: "comment",
            key: "comment",
            width: 200,
            render: (text, record) => (
                <Input
                    value={editingComment[record.id] ?? text ?? ""}
                    onChange={(e) => handleCommentChange(record.id, e.target.value)}
                    onPressEnter={() => handleCommentSubmit(record.id)}
                    placeholder="Digite um comentário..."
                />
            ),
        },
        {
            title: "Mídia Kit",
            dataIndex: "file_url",
            key: "file_url",
            render: (url) =>
                url ? (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <Tag color="blue">Mídia</Tag>
                    </a>
                ) : (
                    <Tag color="gray">Sem mídia</Tag>
                ),
            width: 150,
        },
        {
            title: "Ações",
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => (
                <Dropdown overlay={menu(record.id)} trigger={["click"]}>
                    <ThreeDotsIcon />
                </Dropdown>
            )
        },
    ];

    return (
        <PageContainer route="Influencer">
            <Container>
                {loading ? (
                    <Centralizer>
                        <Spinner />
                    </Centralizer>
                ) : influencersInfo.length !== 0 ? (
                    <TableContainer>
                        <ContentTop>
                        <TotalText>Total de Influencers: {pagination?.total}</TotalText>
                            <Row gutter={[16, 16]}>
                                <Col>
                                    <Select
                                        placeholder="Selecione o Estado"
                                        value={filters.state}
                                        onChange={(value) => setFilters({ ...filters, state: value })}
                                        style={{ width: 200 }}
                                    >
                                        {allBrazilianStates.map((estado) => (
                                            <Select.Option key={estado.sigla} value={estado.sigla}>
                                                {estado.nome}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col>
                                    <Select
                                        placeholder="Selecione a Cidade"
                                        value={filters.city}
                                        onChange={(value) => setFilters({ ...filters, city: value })}
                                        style={{ width: 200 }}
                                        disabled={!filters.state} 
                                    >
                                        <Select.Option value="">Todas as Cidades</Select.Option>
                                        {[...new Set(influencersInfo
                                            .filter(i => i.state === filters.state)
                                            .map(i => i.city))]
                                            .map(city => (
                                                <Select.Option key={city} value={city}>{city}</Select.Option>
                                            ))}
                                    </Select>
                                </Col>
                            </Row>
                        </ContentTop>
                        <Table
                            columns={columns}
                            dataSource={filteredData.map((entity) => ({ key: entity.id, ...entity }))}
                            style={{ overflowY: 'scroll' }}
                            scroll={{ x: 'max-content' }}
                            pagination={{
                                current: pagination.current,
                                pageSize: pagination.pageSize,
                                total: pagination.total,
                                showSizeChanger: true,
                            }}
                            onChange={handleTableChange}
                        />
                    </TableContainer>
                ) : (
                    <Empty description="Não há dados na tabela!" />
                )}
            </Container>
        </PageContainer>
    );
}

export default Influencer;
