import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import api from "../../services/api";
import { verifyPermission } from "../../services/auth";
import { rucFormater } from "../../services/rucFormater";
import { cpfCnpjFormater } from "../../services/cpfCnpjFormater";

import Brasil from "../../assets/image/brasil.webp";
import Paraguai from "../../assets/image/paraguai.webp";

import Spinner from "../../components/Spinner";
import UserCompanies from "../../components/UserCompanies";

import NfceForm from "../NfceForm";
import FocusForm from "../FocusForm";
import ModalAuditStore from "../ModalAuditStore";
import ModalFranchiseeInfo from "../ModalFranchiseeInfo";
import ModalFranchiseeAudit from "../ModalFranchiseeAudit";

import { User } from "../../models/entities/user";
import { CompanyUsers } from "../../models/entities/users";
import { FranchiseeInfo } from "../../models/entities/franchisee";
import { Company as CompanyModel } from "../../models/entities/company";

import moment from "moment";

import { Tooltip, message as messageAnt, notification } from "antd";

import {
  Table,
  MoreIcon,
  Dropdown,
  Menu,
  Modal,
  ModalCompanyUsers,
  Country,
  InformationCirclIcon,
} from "./styles";

const { SubMenu } = Menu;
interface IProps {
  setVisible: Dispatch<SetStateAction<boolean>>;
  company: CompanyModel | null;
  setCompany: Dispatch<SetStateAction<CompanyModel | null>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  data: CompanyModel[];
  profileList: User[] | null;
  setVisibleCompanyInfoModal: Dispatch<SetStateAction<boolean>>;
}

export interface AuditStore {
  created_at: string;
  deleted_at: string | null;
  field: string;
  id: number;
  new_value: string;
  old_value: string;
  store_id: number;
  user_id: number;
  user_name: string;
}

const CompanyList: React.FC<IProps> = ({
  data,
  setCompany,
  setShouldSearch,
  company,
  setVisible,
  profileList,
  setVisibleCompanyInfoModal,
}) => {
  const [nfceVisible, setNfceVisible] = useState(false);
  const [focusVisible, setFocusVisible] = useState(false);
  const [visibleUsers, setVisibleUsers] = useState(false);
  const [visibleAuditStore, setVisibleAuditStore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [loadingAudit, setLoadingAudit] = useState(false);
  const [companyUsers, setCompanyUsers] = useState<CompanyUsers[]>([]);
  const [auditData, setAuditData] = useState<AuditStore[]>([]);
  const [companyId, setCompanyId] = useState<number | null>(null);

  const [auditFranchiseeData, setAudiFranchiseetData] = useState<any[]>([]);
  const [franchiseeInfo, setFranchiseeInfo] = useState<FranchiseeInfo[]>([]);
  const [visibleFranchiseeInfo, setVisibleFranchiseeInfo] = useState(false);
  const [visibleFranchiseeAudit, setVisibleFranchiseeAudit] = useState(false);
  const [loadingFranchiseeInfo, setLoadingFranchiseeInfo] = useState(false);

  useEffect(() => {
    const fetchAuditData = async () => {
      if (companyId) {
        setLoadingAudit(true);
        try {
          const {
            data: { content },
          } = await api.get(`/company-audit/${companyId}`);
          setAuditData(content);
        } catch (error: any) {
          messageAnt.error(error?.response?.data?.message);
        } finally {
          setLoadingAudit(false);
        }
      }
    };

    if (visibleAuditStore) fetchAuditData();
  }, [visibleAuditStore, companyId]);

  useEffect(() => {
    const fetchFranchiseeAuditData = async () => {
      if (companyId) {
        setLoadingAudit(true);
        try {
          const {
            data: { content },
          } = await api.get(`/company-franchisee-audit?store_id=${companyId}`);
          setAudiFranchiseetData(content);
        } catch (error: any) {
          messageAnt.error(error?.response?.data?.message);
        } finally {
          setLoadingAudit(false);
        }
      }
    };

    if (visibleFranchiseeAudit) fetchFranchiseeAuditData();
  }, [visibleFranchiseeAudit, companyId]);

  useEffect(() => {
    const fetchFranchiseeInfo = async () => {
      if (companyId) {
        setLoadingFranchiseeInfo(true);
        try {
          const {
            data: { content },
          } = await api.get(`/company-franchisee?company_id=${companyId}`);

          setFranchiseeInfo(content);
        } catch (error: any) {
          messageAnt.error(
            "Oops! Não foi possível buscar as informações dos franqueados!" +
              error
          );
        } finally {
          setLoadingFranchiseeInfo(false);
        }
      }
    };
    if (visibleFranchiseeInfo) fetchFranchiseeInfo();
  }, [visibleFranchiseeInfo, companyId]);

  const onDelete = (id: number) => {
    Modal.confirm({
      title: "Remover empresa",
      content: "Deseja continuar e remover este cadastro?",
      okText: "Sim",
      centered: true,
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        try {
          await api.delete(`/company/${id}`);
          setShouldSearch(true);
        } catch (error: any) {
          messageAnt.error(error.response.data.message);
        }
      },
    });
  };

  const onDeleteFocus = (id) => {
    Modal.confirm({
      title: "Deseja realmente desvincular empresa na focus?",
      okText: "Sim",
      centered: true,
      okType: "primary",
      cancelText: "Não",
      width: 500,
      async onOk() {
        try {
          await api.delete(`/company/focus/${id}`);
          notification.success({
            message: `Empresa desvinculada da Focus com sucesso`,
            duration: 3,
          });
          setShouldSearch(true);
        } catch (e: any) {
          console.log(e.response);
          const _description = e?.response?.data?.message;
          notification.error({
            message: `Erro ao desvincular empresa na Focus`,
            description: _description || "",
            duration: 3,
          });
        }
      },
    });
  };

  const onHomologNfce = (company: CompanyModel) => {
    Modal.confirm({
      title: "Homologar emissão de nfce",
      content: "Deseja continuar e realizar a homologação?",
      okText: "Sim",
      centered: true,
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        try {
          setLoadingAction(true);
          await api.put(`/company/enable-nfce/${company.id}`);
        } catch (e) {
          //@ts-ignore
          const _description = e?.response?.data?.message;

          notification.error({
            message: `Erro ao homologar emissão da nfce`,
            description: _description,
            duration: 3,
          });
        } finally {
          setLoadingAction(false);
        }
      },
    });
  };

  useEffect(() => {
    const fetchCompaniesUsers = async () => {
      setLoading(true);
      try {
        const {
          data: { content },
        } = await api.get(`/companyUser/${companyId}/company`);
        setCompanyUsers(content);
      } catch {
        messageAnt.error("Falha ao obter empresas do usuário");
      } finally {
        setLoading(false);
        setCompanyId(null);
      }
    };
    if (companyId) {
      fetchCompaniesUsers();
    }
  }, [companyId]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "País",
      dataIndex: "country",
      key: "country",
      render: (text, record) => (
        <>
          {record.country === "brasil" ? (
            <Tooltip title="Brasil">
              <Country src={Brasil}></Country>
            </Tooltip>
          ) : (
            <Tooltip title="Paraguai">
              <Country src={Paraguai}></Country>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: "Nome",
      dataIndex: "company_name",
      key: "company_name",
      render: (text, record) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      ),
    },
    {
      title: "CNPJ / RUC",
      dataIndex: "cnpj",
      key: "cnpj",
      render: (text, record) => {
        if (record?.country === "brasil") {
          return cpfCnpjFormater(record?.cnpj);
        } else if (record?.country === "paraguai") {
          return rucFormater(record?.ruc);
        }
        return "CNPJ/RUC não foi enviado";
      },
    },
    {
      title: "Cidade",
      dataIndex: "city",
      key: "city",
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>
          {text ? text : "Indefinida"}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "company_status",
      key: "company_status",
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>
          {text === true ? "Ativa" : "Inativa"}
        </span>
      ),
    },
    {
      title: "Cadastro NFC-e",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const isBrazil = record?.country === "brasil";
        const isParaguay = record?.country === "paraguai";

        const isActived =
          (isBrazil &&
            record?.token_focus_production &&
            record.token_focus_homologation) ||
          (isParaguay && record?.secret_key_py);

        const isExpired =
          isBrazil &&
          record?.token_focus_production &&
          record.token_focus_homologation &&
          moment(record?.expiration_certificate) <= moment();

        const expirationDate = record?.expiration_certificate
          ? moment(record?.expiration_certificate).format("DD-MM-YYYY")
          : null;

        return (
          <div className="certificate">
            {isActived ? (
              isExpired ? (
                <span className="expired">Expirado</span>
              ) : (
                <span className="actived">Ativo</span>
              )
            ) : (
              <span className="no-actived">Ausente</span>
            )}
            {expirationDate && (
              <span className="expiration_date">
                Certificado válido até: {expirationDate}
              </span>
            )}
          </div>
        );
      },
    },

    {
      dataIndex: "company_id_focus",
      key: "company_id_focus",
      title: (
        <span>
          Vinculada com a Focus{" "}
          <Tooltip title="Indica se a empresa está vinculada com a Focus">
            <InformationCirclIcon />
          </Tooltip>
        </span>
      ),
      render: (_, record) => {
        return (
          <span
            className={`${
              record?.company_id_focus ? "actived-focus" : "no-actived-focus"
            }`}
          >
            {record?.company_id_focus ? "Sim" : "Não"}
          </span>
        );
      },
    },

    {
      dataIndex: "web_hook_ciencia_nf_focus_id",
      key: "web_hook_ciencia_nf_focus_id",
      title: (
        <span>
          Ciência Automática{" "}
          <Tooltip title="Para baixar os pdfs das notas fiscais da amadelli, é necessário ter a ciência automática habilitada na focus.">
            <InformationCirclIcon />
          </Tooltip>
        </span>
      ),
      render: (_, record) => {
        return (
          <span
            className={`${
              record?.web_hook_ciencia_nf_focus_id
                ? "actived-web-hook"
                : "no-actived-web-hook"
            }`}
          >
            {record?.web_hook_ciencia_nf_focus_id ? "Sim" : "Não"}
          </span>
        );
      },
    },

    {
      title: "Ação",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu key={record.id}>
                {verifyPermission("company.edit_contract_information") && (
                  <Menu.Item
                    onClick={() => {
                      setCompany(record);
                      setVisible(true);
                    }}
                    key="1"
                  >
                    <span>Editar Empresa</span>
                  </Menu.Item>
                )}
                <Menu.Item
                  onClick={() => {
                    setCompany(record);
                    setVisible(true);
                    setVisibleCompanyInfoModal(true);
                  }}
                  key="12"
                >
                  <span>Visualizar Dados da Empresa</span>
                </Menu.Item>

                {record.country === "brasil" &&
                  (verifyPermission("company.focus_register") ||
                    verifyPermission("company.focus_edit")) && (
                    <Menu.Item
                      onClick={() => {
                        setFocusVisible(true);
                        setCompany(record);
                      }}
                      key="2"
                    >
                      <span>
                        {record?.company_id_focus
                          ? "Editar dados na Focus"
                          : "Vincular empresa na Focus"}
                      </span>
                    </Menu.Item>
                  )}
                {record.country === "brasil" &&
                  record?.company_id_focus &&
                  verifyPermission("company.focus_delete") && (
                    <Menu.Item onClick={() => onDeleteFocus(record.id)} key="3">
                      <span style={{ color: "var(--red-600)" }}>
                        {" "}
                        Desvincular empresa na Focus
                      </span>
                    </Menu.Item>
                  )}

                <Menu.Item
                  onClick={() => {
                    setVisibleUsers(true);
                    setCompanyId(record.id);
                  }}
                  key="4"
                >
                  <span>Lista de Usuários</span>
                </Menu.Item>
                {record.country === "brasil" &&
                  record.token_focus_homologation &&
                  verifyPermission("company.homolog_nfce") && (
                    <>
                      {loadingAction ? (
                        <Menu.Item key="5">
                          <Spinner />
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          onClick={() => onHomologNfce(record)}
                          key="5"
                        >
                          <span>Homologar Emissão de NFCe</span>
                        </Menu.Item>
                      )}
                    </>
                  )}

                <Menu.Item
                  onClick={() => {
                    setVisibleFranchiseeInfo(true);
                    setCompany(record);
                    setCompanyId(record.id);
                  }}
                  key="6"
                >
                  <span>Informações dos franqueados</span>
                </Menu.Item>
                <SubMenu key="sub1" title="Auditorias">
                  <Menu.Item
                    onClick={() => {
                      setVisibleAuditStore(true);
                      setCompany(record);
                      setCompanyId(record.id);
                    }}
                    key="7"
                  >
                    <span>Auditoria da loja</span>
                  </Menu.Item>
                  <Menu.Item
                    key="71"
                    onClick={() => {
                      setVisibleFranchiseeAudit(true);
                      setCompany(record);
                      setCompanyId(record.id);
                    }}
                  >
                    <span>Auditoria do franqueado</span>
                  </Menu.Item>
                </SubMenu>
                {verifyPermission("company.delete_contract_information") && (
                  <Menu.Item
                    onClick={() => onDelete(record.id)}
                    key="8"
                    style={{ borderTop: "1px solid var(--gray-100)" }}
                  >
                    <span style={{ color: "var(--red-600)" }}>
                      Remover Empresa
                    </span>
                  </Menu.Item>
                )}
              </Menu>
            }
            placement="bottomRight"
            arrow
          >
            <MoreIcon />
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data.map((entity) => ({ key: entity.id, ...entity }))}
      />

      <NfceForm
        company={company}
        visible={nfceVisible}
        setVisible={setNfceVisible}
        setShouldSearch={setShouldSearch}
      />

      <FocusForm
        company={company}
        setCompany={setCompany}
        visible={focusVisible}
        setVisible={setFocusVisible}
        setShouldSearch={setShouldSearch}
      />

      <ModalCompanyUsers
        title="Usuários vinculados"
        centered
        width={1000}
        visible={visibleUsers}
        footer={false}
        onCancel={() => {
          setVisibleUsers(false);
        }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <UserCompanies
            data={companyUsers}
            company={company}
            profileList={profileList}
          />
        )}
      </ModalCompanyUsers>

      <ModalAuditStore
        visible={visibleAuditStore}
        setVisible={setVisibleAuditStore}
        company={company}
        loading={loadingAudit}
        setShouldSearch={setShouldSearch}
        auditData={auditData}
      />

      <ModalFranchiseeInfo
        visible={visibleFranchiseeInfo}
        setVisible={setVisibleFranchiseeInfo}
        company={company}
        loading={loadingFranchiseeInfo}
        franchiseeData={franchiseeInfo}
      />

      <ModalFranchiseeAudit
        visible={visibleFranchiseeAudit}
        setVisible={setVisibleFranchiseeAudit}
        company={company}
        loading={loadingAudit}
        auditFranchiseeData={auditFranchiseeData}
      />
    </>
  );
};

export default CompanyList;
